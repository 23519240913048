import React from 'react';
import Link from 'next/link';
import { breadcrumbVariants } from 'tailwindVariants/components/breadcrumbTailwindVariant';
import clsx from 'clsx';
import IconHelper from 'src/helpers/commonComponents/IconHelper';

interface BreadcrumbItem {
  breadcrumbTitle?: { value: string };
  title: { value: string };
  url: { path: string };
  id: string;
}

interface SiblingItem {
  breadcrumbTitle?: { value: string };
  title: { value: string };
  url: { path: string };
  id: string;
}

export interface BreadcrumbData {
  breadcrumb: {
    breadcrumbTitle?: { value: string };
    title: { value: string };
    url: { path: string };
    id: string;
    ancestors: BreadcrumbItem[];
  };
  siblings: {
    children: {
      results: SiblingItem[];
    };
  };
}

interface BreadCrumbWithSiblingsProps {
  gqldata: BreadcrumbData;
  siteName: string;
}

interface IconProps {
  sitename?: string;
  className?: string;
}

//Tailwind Variants Styles
const {
  breadcrumbText,
  breadcrumbContainer,
  ancestorsWrapper,
  siblingsWrapper,
  siblingsList,
  siblingItem,
  siblingLink,
} = breadcrumbVariants({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const DividerIcon = () => {
  return (
    <span className={''}>
      <IconHelper icon={'breadcrumb-divider'} />
    </span>
  );
};

const LinkIcon = ({ className }: IconProps): JSX.Element => {
  return (
    <span className={className}>
      <IconHelper icon={'breadcrumb-down'} className="[&>svg>path]:fill-color-text-brand-1" />
    </span>
  );
};

const buildBreadcrumb = (
  breadcrumbData: BreadcrumbItem[],
  currentItem: BreadcrumbItem
): BreadcrumbItem[] => {
  const pathsToExclude = ['/categories', '/'];
  const filteredAncestors =
    breadcrumbData?.filter((item) => !pathsToExclude.includes(item.url.path)).reverse() || '';

  return [...filteredAncestors, currentItem];
};

const getSiblings = (siblings: SiblingItem[], currentItemId: string): SiblingItem[] => {
  return siblings?.filter((sibling) => sibling.id !== currentItemId && sibling.breadcrumbTitle);
};

const BreadCrumbWithSiblings: React.FC<BreadCrumbWithSiblingsProps> = ({ gqldata, siteName }) => {
  const { breadcrumb, siblings } = gqldata;
  const breadcrumbItems = buildBreadcrumb(breadcrumb?.ancestors, breadcrumb);
  const filteredSiblings = getSiblings(siblings?.children?.results, breadcrumb?.id);

  return (
    <div>
      {/* Breadcrumb Rendering */}
      <nav aria-label="breadcrumb" className={breadcrumbContainer()}>
        <ul className={ancestorsWrapper()}>
          {breadcrumbItems.map((item, index) => {
            const title = item?.breadcrumbTitle?.value || item?.title?.value;
            const isLastItem = index === breadcrumbItems.length - 1;
            const isSecondLastItem = index === breadcrumbItems.length - 2;

            return (
              <li
                key={item?.id}
                className={clsx(
                  ancestorsWrapper(),
                  'relative',
                  'group',
                  isLastItem ? 'flex' : 'hidden'
                )}
              >
                {isLastItem ? (
                  <>
                    <span className={clsx(breadcrumbText())}>{title}</span>
                    {filteredSiblings?.length > 0 && (
                      <LinkIcon sitename={siteName} className="group-hover:rotate-180" />
                    )}
                    {/* Siblings Rendering */}
                    {filteredSiblings?.length > 0 && (
                      <div className={siblingsWrapper()}>
                        <ul className={siblingsList()}>
                          {filteredSiblings.map((sibling, index) => (
                            <li key={sibling?.id} className={siblingItem()}>
                              <Link
                                aria-label={'breadcrumbTitle' + index}
                                href={sibling?.url?.path}
                                className={clsx(siblingLink(), 'block hover:no-underline')}
                              >
                                {sibling?.breadcrumbTitle?.value || sibling?.title?.value}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                ) : isSecondLastItem && breadcrumbItems.length === 3 ? (
                  <>
                    <Link
                      aria-label="title link"
                      href={item?.url?.path}
                      className={clsx(breadcrumbText())}
                    >
                      {title}
                    </Link>
                    <DividerIcon />
                  </>
                ) : (
                  <>
                    <span className={clsx(breadcrumbText())}>{title}</span>
                    {!isLastItem && <DividerIcon />}
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default BreadCrumbWithSiblings;
