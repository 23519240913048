import React, { Dispatch, useEffect, SetStateAction, useState } from 'react';
import { EventDataDocument } from './Events.graphql';
import { Field, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { GetStaticComponentProps, constants } from '@sitecore-jss/sitecore-jss-nextjs';
import { resetEditorChromes } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import {
  HideLinkFromStoreNavigation,
  ShowLinkFromStoreNavigation,
} from 'src/helpers/StoreLandingHelper';
import { DateTime } from 'luxon';
import graphqlClientFactory from 'lib/graphql-client-factory';
import { storeEventsTailwindVariant } from 'tailwindVariants/components/storeEventsTailwindVariant';

interface EventDataProps {
  datasource?: {
    eventsText?: Field<string>;
    noOfEvents?: Field<string>;
    viewLessText?: Field<string>;
    viewMoreText?: Field<string>;
  };
  search: {
    pageInfo?: {
      hasNext?: string;
      endCursor?: string;
    };
    results: EventsProps[];
  };
}
interface EventsProps {
  description: Field<string>;
  fromDateTime: Field<string>;
  title: Field<string>;
  toDateTime: Field<string>;
}
function formatTimeRange(fromTimestamp: Field<string>, toTimestamp: Field<string>): string {
  const fromDate = DateTime.fromMillis(Number(fromTimestamp?.value), { zone: 'utc' });
  const toDate = DateTime.fromMillis(Number(toTimestamp?.value), { zone: 'utc' });
  const formattedFromTime = fromDate.toFormat('h:mm a');
  const formattedToTime = toDate.toFormat('h:mm a');
  const formattedTimeRange = `${formattedFromTime} - ${formattedToTime}`;
  return formattedTimeRange;
}
function formatDate(dateTimestamp: Field<string>): string {
  const formattedDate = DateTime.fromMillis(Number(dateTimestamp?.value), { zone: 'utc' }).toFormat(
    'ccc - LLL d, yyyy'
  );
  return formattedDate;
}
const RenderEvents = ({
  events,
  isShowMoreClicked,
  noOfEvents,
}: {
  events: EventsProps[];
  isShowMoreClicked: boolean;
  noOfEvents?: string;
}) => {
  const {
    eventWrapper,
    eventLeftContent,
    eventDateText,
    eventContentWrapper,
    eventDescription,
    eventName,
    eventTime,
  } = storeEventsTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const eventsData = noOfEvents && !isShowMoreClicked ? events.slice(0, +noOfEvents) : events;
  return (
    <>
      {filterEvents(eventsData)
        ?.sort((firstEvent: EventsProps, secondEvent: EventsProps) => {
          const dateOfFirstEvent = DateTime.fromFormat(
            firstEvent?.fromDateTime?.value,
            "yyyyMMdd'T'HHmmssZZ"
          );
          const dateOfSecondEvent = DateTime.fromFormat(
            secondEvent?.fromDateTime?.value,
            "yyyyMMdd'T'HHmmssZZ"
          );
          return dateOfFirstEvent?.valueOf() - dateOfSecondEvent?.valueOf();
        })
        ?.map((event: EventsProps, index: number) => (
          <div className={eventWrapper()} key={index}>
            <Text
              tag="p"
              className={eventDateText()}
              field={{ value: formatDate(event?.fromDateTime) }}
            />
            <div className={eventContentWrapper()}>
              <div className={eventLeftContent()}>
                <Text
                  tag="p"
                  className={eventTime()}
                  field={{ value: formatTimeRange(event?.fromDateTime, event?.toDateTime) }}
                />
                <div>
                  <Text tag="p" className={eventName()} field={event?.title} />
                  <RichText tag="p" className={eventDescription()} field={event?.description} />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
const ShowMoreLessFunctionality = ({
  onClickOfShowMore,
  isShowMoreClicked,
  viewLessText,
  viewMoreText,
}: {
  onClickOfShowMore: Dispatch<SetStateAction<boolean>>;
  isShowMoreClicked: boolean;
  viewLessText: string | undefined;
  viewMoreText: string | undefined;
}) => {
  const { viewMoreWrapper, viewMoreIcon, viewMoreLink } = storeEventsTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  return (
    <div className={viewMoreWrapper()}>
      <LinkHelper
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          onClickOfShowMore(!isShowMoreClicked);
        }}
        hideLinkInEE={true}
        field={{ value: { text: viewMoreText, href: '/' } }}
        className={viewMoreLink()}
      >
        <>
          {isShowMoreClicked ? (
            <>
              {viewLessText && (
                <>
                  {viewLessText}
                  <IconHelper icon={'chevron-up'} className={viewMoreIcon()} />
                </>
              )}
            </>
          ) : (
            <>
              {viewMoreText && (
                <>
                  {viewMoreText}
                  <IconHelper icon={'chevron-down'} className={viewMoreIcon()} />
                </>
              )}
            </>
          )}
        </>
      </LinkHelper>
    </div>
  );
};
const filterEvents = (events: EventsProps[]) => {
  return events?.filter((event) => {
    const eventDate = DateTime.fromFormat(event?.fromDateTime?.value, "yyyyMMdd'T'HHmmssZZ");
    return eventDate > DateTime.now();
  });
};
const Events = ({ datasource, search: { results } }: EventDataProps): JSX.Element => {
  useEffect(() => {
    resetEditorChromes();
  }, []);
  const [isShowMoreClicked, setISShowMoreClicked] = useState(false);
  const { base, heading } = storeEventsTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  useEffect(() => {
    const newResults = filterEvents(results);
    if (!(newResults?.length > 0)) {
      HideLinkFromStoreNavigation('events-specials');
    } else {
      ShowLinkFromStoreNavigation('events-specials');
    }
  }, [results?.length > 0]);
  return (
    <>
      {filterEvents(results)?.length > 0 && (
        <div className={base()} id="events-specials">
          <Text tag="p" className={heading()} field={datasource?.eventsText} />
          <RenderEvents
            events={results}
            isShowMoreClicked={isShowMoreClicked}
            noOfEvents={datasource?.noOfEvents?.value}
          />
          {datasource?.noOfEvents?.value && results?.length > +datasource?.noOfEvents?.value && (
            <ShowMoreLessFunctionality
              onClickOfShowMore={setISShowMoreClicked}
              isShowMoreClicked={isShowMoreClicked}
              viewLessText={datasource?.viewLessText?.value}
              viewMoreText={datasource?.viewMoreText?.value}
            />
          )}
        </div>
      )}
    </>
  );
};
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (process.env.JSS_MODE === constants.JSS_MODE.DISCONNECTED) {
    return null;
  }
  const graphQLClient = graphqlClientFactory({});
  const result = await graphQLClient
    .request<EventDataProps>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      EventDataDocument as any,
      {
        datasource: rendering.dataSource,
        contextItem: layoutData?.sitecore?.route?.itemId,
        language: layoutData?.sitecore?.context?.language,
        after: '',
      }
    )

    .then(async (data) => {
      while (data?.search?.pageInfo?.hasNext) {
        await graphQLClient
          .request<EventDataProps>(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            EventDataDocument as any,
            {
              datasource: rendering.dataSource,
              contextItem: layoutData?.sitecore?.route?.itemId,
              language: layoutData?.sitecore?.context?.language,
              after: data?.search?.pageInfo?.endCursor,
            }
          )
          .then(async (newdata) => {
            data?.search?.results?.push(...newdata?.search?.results);
            data.search.pageInfo = newdata?.search?.pageInfo;
            return data;
          });
      }
      return data;
    });
  return result;
};
export default Events;
