import { LinkField, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { createContext, useContext } from 'react';
import useExperienceEditor from '../../hooks/useExperienceEditor';
import { normalizeLinkField } from 'lib/utils/url-formatter';

interface LinkProps {
  field?: LinkField;
  className?: string;
  hideLinkInEE?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  buttonClickHandler?: () => void;
  editable?: boolean;
  type?: string;
  attr?: string;
  asButton?: boolean;
}

/** Context to determine whether we are currently inside of a link and thus have a nested link scenario which is invalid */
const LinkContext = createContext<boolean>(false);

const LinkHelper: React.FC<React.PropsWithChildren<LinkProps>> = ({
  field,
  className,
  children,
  hideLinkInEE,
  onClick,
  editable,
  type,
  attr,
  asButton,
  buttonClickHandler,
}) => {
  const isEE = useExperienceEditor();

  const isNestedLink = useContext(LinkContext);

  // Process the href,querystring, and other attributes before rendering
  const processedField = normalizeLinkField(field?.value);

  if (!field) {
    return <></>;
  }
  if (isEE) {
    return (
      <>
        {!hideLinkInEE && (
          <Link
            aria-label={field?.value?.text}
            field={field}
            className={className}
            editable={editable}
          ></Link>
        )}
        <LinkContext.Provider value={true}>{children}</LinkContext.Provider>
      </>
    );
  }
  // If we're already inside a link, a link inside a link is invalid and causes a hydration error because the browser renders it differently.

  if (isNestedLink) {
    if (asButton === true) {
      return !isEE ? (
        <button aria-label={field?.value?.text} onClick={buttonClickHandler} className={className}>
          {field?.value?.text}
        </button>
      ) : (
        <Link
          aria-label={field?.value?.text}
          field={processedField}
          className={className}
          editable={editable}
        />
      );
    } else {
      return (
        <span
          aria-label={field?.value?.text ?? field?.value?.href}
          data-attr={attr}
          className={className}
          onClick={onClick}
        >
          {children}
        </span>
      );
    }
  }

  return (
    <>
      {field?.value?.href && field?.value?.href?.length > 0 ? (
        <Link
          aria-label={field?.value?.text ?? field?.value?.href}
          data-attr={attr}
          type={type}
          field={{
            value: processedField,
          }}
          className={className}
          onClick={onClick}
          editable={editable}
        >
          {children && <LinkContext.Provider value={true}>{children}</LinkContext.Provider>}
        </Link>
      ) : (
        children && <LinkContext.Provider value={true}>{children}</LinkContext.Provider>
      )}
    </>
  );
};
export default LinkHelper;
