import { tv } from 'tailwind-variants';

export const breadcrumbVariants = tv(
  {
    slots: {
      breadcrumbContainer: [
        'flex',
        'bg-color-scale-8-dark',
        'text-color-scale-1-white',
        'items-center',
      ],
      breadcrumbText: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      ancestorsWrapper: ['items-center', 'min-h-[44px]'],
      siblingsWrapper: [
        'transition-opacity',
        'ease-in-out',
        'delay-0',
        'duration-100',
        'absolute',
        'left-0',
        'top-[44px]',
        'bg-color-scale-1-white',
        'text-black',
        'invisible',
        'opacity-0',
        'group-hover:visible',
        'group-hover:opacity-100',
        'z-[1]',
      ],
      siblingsList: [
        'flex',
        'flex-col',
        'items-start',
        'max-h-[128px]',
        'overflow-y-auto',
        'min-w-[124px]',
      ],
      siblingItem: ['w-full', 'hover:bg-color-scale-2-light'],
      siblingLink: [
        'font-primary',
        'text-body-xSmall-regular',
        'font-body-xSmall-regular',
        'leading-body-xSmall-regular',
      ],
    },
    compoundSlots: [],
    variants: {
      device: {
        mobile: {
          breadcrumbContainer: [
            'px-mob-global-grid-margin',
            'gap-mob-space-between-tight-horizontal',
          ],
          ancestorsWrapper: ['gap-mob-space-between-tight-horizontal', 'w-full', 'justify-between'],
          breadcrumbText: ['py-mob-global-spacing-spacing-1', 'px-mob-global-spacing-spacing-3'],
          siblingsWrapper: ['w-full'],
          siblingItem: [],
          siblingLink: ['py-mob-global-spacing-spacing-2', 'px-mob-global-spacing-spacing-4'],
        },
        desktop: {
          breadcrumbContainer: [
            'px-desk-global-grid-margin',
            'gap-desk-space-between-tight-horizontal',
          ],
          ancestorsWrapper: [
            'flex',
            'gap-desk-space-between-tight-horizontal',
            'w-auto',
            'justify-center',
          ],
          breadcrumbText: ['py-desk-global-spacing-spacing-1', 'px-desk-global-spacing-spacing-3'],
          siblingsWrapper: ['w-auto'],
          siblingItem: [],
          siblingLink: ['py-desk-global-spacing-spacing-2', 'px-desk-global-spacing-spacing-4'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
