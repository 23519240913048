//global
import React, { useContext } from 'react';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import ComponentContext from 'lib/context/ComponentContext';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useOcSelector } from 'src/redux/ocStore';
import { useTheme } from 'lib/context/ThemeContext';
import { closedText } from 'src/helpers/Constants';
import { changeMyStoreTailwindVariant } from 'tailwindVariants/components/changeMyStoreTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
// import { useSelector } from 'react-redux';
//type
export type ChangeMyStoreProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.ChangeMyStoreWidget.Fields.ChangeMyStoreWidget;
//component variants
export type theme = 'psp' | 'wnw';

//main component
const ChangeMyStore: React.FC<ChangeMyStoreProps> = ({ fields, rendering }): JSX.Element => {
  const { themeName } = useTheme();
  const {
    base,
    storeName,
    storeAddress,
    timingWrapper,
    storeNumber,
    storeTiming,
    cta,
    buttonWrapper,
    icon,
  } = changeMyStoreTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
    theme: themeName,
  });
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const router = useRouter();
  const now = new Date();
  const day = now.getDay();
  //Store Data
  const storeData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      className={base({
        className: `${componentContextData?.isChangeStoreOpen ? 'block' : 'hidden'}`,
      })}
    >
      <IconHelper
        icon="close"
        className={icon()}
        onClick={() => {
          setcomponentContextData({ ...componentContextData, isChangeStoreOpen: false });
        }}
      />
      <div id={'store-detial'}>
        <p className={storeName()}>{storeData?.storeName}</p>
        <p className={storeAddress()}>{storeData?.street1}</p>
        <p className="text-2">
          {storeData?.city}, {storeData?.state} {storeData?.zip}
        </p>
        <Link
          aria-label="phone number link"
          href={`tel:${storeData?.phone}`}
          className={storeNumber()}
        >
          {storeData?.phone}
        </Link>
        <div className={timingWrapper()}>
          {storeData?.hours &&
          Object?.values(storeData?.hours)?.[day] &&
          Object?.values(storeData?.hours)?.[day] === closedText ? (
            <span
              className={storeTiming({
                className: '!font-body-small-bold',
              })}
            >
              {storeData?.hours && Object?.values(storeData?.hours)?.[day]?.toUpperCase()}
            </span>
          ) : (
            <>
              <RichTextHelper field={fields?.storeTimingLabel} className={storeTiming()} />
              <span>{storeData?.hours && (Object?.values(storeData?.hours)?.[day] as string)}</span>
            </>
          )}
        </div>
      </div>
      <div id={'store-change'} className={buttonWrapper()}>
        <button
          aria-label="store-change"
          className={cta()}
          onClick={() => {
            setcomponentContextData({
              ...componentContextData,
              showStoreLocatorGlobalModal: true,
              isChangeStoreOpen: false,
            });
          }}
        >
          <TextHelper field={fields?.changeStoreLabel} />
        </button>
        <LinkHelper
          onClick={(e) => {
            e.preventDefault();
            router.push(
              `/store/${storeData?.state?.toLowerCase()}/${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}-${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}`?.replaceAll(
                ' ',
                '-'
              )
            );
            setcomponentContextData({ ...componentContextData, isChangeStoreOpen: false });
          }}
          field={{
            value: {
              href: `/store/${storeData?.state?.toLowerCase()}/${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}-${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}`?.replaceAll(
                ' ',
                '-'
              ),
              text: fields?.storeDetailsCTA?.value?.text,
            },
          }}
          className={cta()}
        />
      </div>
      <Placeholder name="change-store-personalised" rendering={rendering} />
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<ChangeMyStoreProps>(ChangeMyStore);
